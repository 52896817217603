import React, { useState, useEffect, useRef } from "react";
import api from "../../helper/auth_interceptor";
import { handleSort } from "./handleSort";
import { useTranslation } from "react-i18next";
import { getUser } from "../../helper/auth_helper";
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./projectList.modules.css";
import useExport from "../shared/useExport";
import ProjectTable from "./components/projectListTable";
import ProjectListFilters from "./components/projectListFilter";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import hamburger from "../../images/hamburger.jpg";
import { AccessProvider } from "./context/accessControl";

function ProjectList({ suprmenAdmin, orgAdmin, viewer, regular }) {
  //constant and state declarations

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    projectName: true,
    orgName: true,
    role: true,
    startDate: true,
    endDate: true,
    actions: true,
  });

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const dateRangeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [project, setProject] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openRows, setOpenRows] = useState({});
  const [rowData, setRowData] = useState({});

  const handleRowClick = async (rowData) => {
    // Toggle the dropdown for the clicked row
    setOpenRows((prev) => ({
      ...prev,
      [rowData.id]: !prev[rowData.id],
    }));

    // If the row is being opened, fetch the data
    if (!openRows[rowData.id]) {
      const data = await fetchData(rowData.id);
      // Replace with your actual data fetching function
      setRowData((prev) => ({
        ...prev,
        [rowData.id]: data,
      }));
    }
  };

  //function to fetch data
  const fetchData = async (id) => {
    const user = await getUser();

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/project/${id}`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    // Replace with your actual API call
    const response = await api.get(apiUrl, {
      headers,
    });

    const data = response.data;
    return data;
  };

  const handleSortWrapper = async (column, page) => {
    await handleSort(
      column,
      page,
      sortedColumn,
      isAscending,
      setSortedColumn,
      setIsAscending,
      currentPage,
      selectedPageSize,
      requestBody,
      setProject,
      setPageNumbers
    );
  };

  const requestBody = useRef({
    status: null,
    startDate: null,
    endDate: null,
    searchText: null,
  });

  const [sortedColumn, setSortedColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);

  const toggleClearFilter = () => {
    window.location.reload();
  };

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchProjectDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);

  const pageNumber = currentPage.current;

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchProjectDetails();
  };

  // function to fetch project details
  async function fetchProjectDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    const selectedSize = selectedPageSize.current;

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/filter?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });

      setProject(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //function to handle export file
  const { handleExport } = useExport();
  async function exportFunction() {
    const sortedOrder = isAscending ? "asc" : "desc";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/export-project-list?sortBy=${sortedColumn}&sortOrder=${sortedOrder}`;
    const fileName = "ProjectList_ " + new Date().toLocaleDateString();
    handleExport(fileName, apiUrl, requestBody.current);
  }

  //function for column selection, a filtering option for column in tables.
  const initializeSelectedColumns = async () => {
    const user = await getUser();

    const page = "PROJECT";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`;

    try {
      const response = await api.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const savedSettings = response.data.selectedColumns.split(",");
      const defaultColumns = {
        projectName: true,
        orgName: true,
        role: true,
        startDate: true,
        endDate: true,
        actions: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }

      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        projectName: true,
        orgName: true,
        role: true,
        startDate: true,
        endDate: true,
        actions: true,
      };
    }
  };

  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  //function to save column visibility
  const saveColumnVisibility = async (updatedColumns) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`;

    const userPreference = {
      page: "PROJECT",
      selectedColumns: Object.keys(updatedColumns)
        .filter((key) => updatedColumns[key])
        .join(","),
    };

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await api.post(apiUrl, userPreference, { headers });
    } catch (error) {
      console.error("Error saving column selection:", error);
    }
  };
  //funtion to reset columns
  const handleResetColumns = () => {
    const defaultColumns = {
      projectName: true,
      orgName: true,
      role: true,
      startDate: true,
      endDate: true,
      actions: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const columns = Object.keys(selectedColumns).filter(
    (column) => selectedColumns[column]
  );

  //function for column selection
  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  if (!project) {
    return <div>Loading...</div>;
  }

  const handleEditClick = (rowData) => {
    if (!rowData.viewable) {
      return;
    }
    navigate("/editProject/" + rowData.id, { state: { rowData: project } });
  };

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchProjectDetails();
  };

  const getProjectRoleDisplayText = (role) => {
    const roleMapping = {
      SUPRMEN_ADMIN: t("SupRmen admin"),
      PROJECT_VIEWER: t("Project viewer"),
      PROJECT_USER: t("Project user"),
      PROJECT_LEADER: t("Project leader"),
    };
    return roleMapping[role] || role;
  };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input
          id="slide-sidebar"
          className="checkInputProjectList"
          type="checkbox"
          role="button"
        />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("pageHeader_projectList")}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t("pageHeaderBtn_projectList")}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="projectlist-record">
                  <div class="projectlist-details">
                    <div className="form-head list-project">
                      <div>
                        <h2 className="pageHeading">
                          {t("tableHeading_projectList")}
                        </h2>
                      </div>
                    </div>
                    <ProjectListFilters
                      viewer={viewer}
                      regular={regular}
                      suprmenAdmin={suprmenAdmin}
                      orgAdmin={orgAdmin}
                      t={t}
                      handleSearchFormSubmit={handleSearchFormSubmit}
                      requestBody={requestBody}
                      fetchProjectDetails={fetchProjectDetails}
                      toggleClearFilter={toggleClearFilter}
                      exportFunction={exportFunction}
                      setShowCalendar={setShowCalendar}
                      showCalendar={showCalendar}
                      selectedDateRange={selectedDateRange}
                      setSelectedDateRange={setSelectedDateRange}
                      dateRangeRef={dateRangeRef}
                    />
                    <AccessProvider
                      suprmenAdmin={suprmenAdmin}
                      orgAdmin={orgAdmin}
                      viewer={viewer}
                      regular={regular}
                    >
                      <ProjectTable
                        project={project}
                        openRows={openRows}
                        rowData={rowData}
                        handleRowClick={handleRowClick}
                        handleEditClick={handleEditClick}
                        handleSortWrapper={handleSortWrapper}
                        sortedColumn={sortedColumn}
                        isAscending={isAscending}
                        t={t}
                        pageNumbers={pageNumbers}
                        pageNumber={pageNumber}
                        handlePageChange={handlePageChange}
                        handlePageSize={handlePageSize}
                        getProjectRoleDisplayText={getProjectRoleDisplayText}
                        columns={columns}
                        selectedColumns={selectedColumns}
                        onToggleColumn={handleToggleColumn}
                        handleResetColumns={handleResetColumns}
                      />
                    </AccessProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectList;
