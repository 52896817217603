import React, { useState, useContext, useEffect } from "react";
import styles from "./addressListPage.module.css";
import pdfIcon from "../../../../images/pdfFileIcon.png";
import dullPdf from "../../../../images/dullPdf.png";
import { exportAddressReport } from "../api/downloadReports";
import { PageHeader } from "../../../../helperComponents/pageHeader/PageHeader";
import { DataTable } from "./listingTable/listingTable";
import { useParams } from "react-router-dom";
import { projectBuildingData } from "../api/projectBuidlingData";
import { callReportType } from "../api/reportType";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../../../helperComponents/sideBar/context/sideContext";
import { ProtectedSide } from "../../../../helperComponents/sideBar/protectedSide";
import { TableFilter } from "../../../../helperComponents/TableFilter/TableFilters";
import { searchFilter } from "../api/searchFilterApi";

export const AddressListPage = () => {
  const params = useParams();
  const buildingId = params.id;
  const { t } = useTranslation();
  const { isCollapsed } = useContext(SidebarContext);
  const pageName = t("addressList");
  const returnPage = t("myProjects_home");
  const [addressReportList, setAddressReportList] = useState([]);
  const [rowHeadData, setRowHeadData] = useState([]);

  const showFilters = {
    search: true,
    status: false,
    role: false,
    date: false,
    clear: false,
    export: false,
    createNew: false,
  };

  const handleSearch = async (searchItem) => {
    const searchData = await searchFilter(buildingId, searchItem);
    setAddressReportList(searchData.searchData);
  };

  const callprojectBuildingData = async () => {
    const buildingData = await projectBuildingData(buildingId);
    setAddressReportList(buildingData.data.addressReportResponseList);
  };

  const ReportTypes = async () => {
    const reportData = await callReportType();
    setRowHeadData(reportData.data);
  };

  const dynamicColumns = rowHeadData.map((row) => ({
    key: row.title,
    header: row.title,
    visible: true,
    customClass: row.title
  }));

  const columns = [
    { key: "projectName", header: `${t("houseNo")}`, visible: true, customClass:"streetHouseNo" },
    ...dynamicColumns,
  ];

  const getColumnContent = (reportList, columnTitle) => {
    const report = reportList?.find((r) => r.reportType === columnTitle);
    if (report) {
      return (
        <img
          src={pdfIcon}
          onClick={() => exportAddressReport(report.reportId, report.fileName)}
          alt="pdf"
        />
      );
    } else {
      return <img src={dullPdf} alt="dull pdf" />;
    }
  };

  const data = addressReportList?.map((address) => {
    const rowData = {
      projectName: `${address.streetName} ${address.houseNo}`,
    };

    rowHeadData?.forEach((column) => {
      rowData[column.title] = getColumnContent(
        address.reportList,
        column.title
      );
    });

    return {
      ...rowData,
    };
  });

  useEffect(() => {
    callprojectBuildingData();
    ReportTypes();
  }, []);

  return (
    <div className={styles.mainDiv}>
      <ProtectedSide />
      <div
        className={`${styles.contentDiv} ${isCollapsed ? styles.expanded : ""}`}
      >
        <PageHeader pageName={pageName} returnPage={returnPage} />
        <div className={styles.dataTable}>
          <h6 className={styles.dataTableHeading}>{t("allAddresses")}</h6>
          <hr />
          <TableFilter showFilters={showFilters} handleSearch={handleSearch} />
          <DataTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};
