import React from "react";
import { Side } from "./side";
import Protected from "../../components/Protected";

export const ProtectedSide = () => {
  return (
    <>
      <Protected Component={Side} />
    </>
  );
};
