import React from "react";
import cube from "../../../images/cube.png";
import homeAccordian from "../../../images/home-accordian.png";
import babyModalReplacement from "../../../images/abc.png";
import addressDeatils from "../../../images/address-detail.png";
import addressDocuments from "../../../images/address-document.png";
import { exportBuildingReport } from "./api/downloadReports";
import { AddressList } from "./addressList";

export const BuildingDetailsTable = ({
  item,
  buildingId,
  addressReportList,
  rowHeadData,
  buildingReportList
}) => {
    
  return (
    <tr>
      <td colSpan="8">
        <div className="dropdown-content">
          <div className="building-details-block">
            <div className="building-details-heading">
              <img
                className="homeaccordian"
                src={homeAccordian}
                alt="homeAccordian"
              />
              <h2>Building Details</h2>
            </div>

            <div className="building-details-data">
              <div className="building-details-img">
                <img
                  src={
                    item.babyModel
                      ? `data:image/png;base64,${item.babyModel}`
                      : babyModalReplacement
                  }
                  className="babyModalImg"
                  alt="building"
                />
              </div>

              <div className="building-details-sub-data">
                <h2>{item.name}</h2>
                <p>{item.streetName}</p>
                <p>{item.postcode}</p>
                <h2>Pand Id: {item.pandId}</h2>
              </div>
              <div className="building-details-actions">
                {buildingReportList.map((item)=>(
                    <button className="actionsBtn3" type="submit" onClick={()=> exportBuildingReport(item.reportId, item.fileName)}>
                    <img src={cube} alt="NEN2767" /> {item.reportType}
                  </button> 
                ))}
             
                {/* <button className="actionsBtn1">
                  <img src={cube} alt="Explore in 3D" /> Explore in 3D
                </button>
                <button className="actionsBtn2">
                  <img src={excelIcon} alt="Analysis" /> Analysis
                </button>
                <button className="actionsBtn3">
                  <img src={cube} alt="NEN2767" /> NEN2767
                </button> */}
              </div>
            </div>
          </div>
          <div className="address-documents">
            <div className="address-heading-block">
              <div className="address-details">
                <img
                  className="addressDeatils"
                  src={addressDeatils}
                  alt="addressDeatils"
                />
                <h2>Address details</h2>
              </div>
              <div className="centered-content">
                <img
                  className="addressDocuments"
                  src={addressDocuments}
                  alt="addressDocuments"
                />
                <h2>Documents</h2>
              </div>
            </div>
            <div className="address-table-content">
              <AddressList
                buildingId={buildingId}
                addressReportList={addressReportList}
                rowHeadData={rowHeadData}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
