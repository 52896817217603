import React, { useState } from "react";
import styles from "./popup.module.css"; // Create CSS for Popup styling
import copyText from "../../../../../images/copyText.png";

const Popup = ({ drivePath, onClose }) => {
  const [toastMessage, setToastMessage] = useState(""); // State for toast message

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    setToastMessage("Link Copied Successfully");

    // Hide the toast after 2000ms
    setTimeout(() => {
      setToastMessage("");
    }, 2000);
  };

  const addressLink = `${drivePath}`;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <button className={styles.closeBtn} onClick={onClose}>X</button>
        <p>Drive Path Link</p>

        <div>
        <a href={addressLink} target="_blank" rel="noopener noreferrer">
          {addressLink}
        </a>
        <button onClick={() => handleCopyLink(addressLink)}><img className={styles.copyIcon} alt="copy text" src={copyText}/></button>
        
        </div>
         {/* Toast notification */}
         {toastMessage && (
          <div className={styles.toast}>{toastMessage}</div>
        )}
      </div>
    </div>
  );
};

export default Popup;
