import React, { useState, useRef, useEffect } from "react";
import columnSelection from "./../images/column-selection.png";
import checkboxSelection from "./../images/checkbox-selection-dropdown.png";
import { useTranslation } from "react-i18next";

//column selection filter
export const DropdownFilter = ({
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
}) => {
  //state ans constant declarations
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();

  //display names with translations
  const columnDisplayNames = {
    orgName: t("organisationName_table"),
    stad: t("city_table"),
    startDate: t("startDate_table"),
    endDate: t("endDate_table"),
    userName: t("userName_table"),
    email: t("email_table"),
    role: t("role_table"),
    organisation: t("organisation_table"),
    status: t("status_table"),
    projectName: t("projectName_table"),
    clientName: t("clientName_table"),
    expectedDeliveryDate: t("expectedDeliveryDate_table"),
    quoteStatus: t("quoteStatus_table"),
    actions: t("action"),
  };

  //button to open toggle the dropdown
  const handleButtonClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };



  return (
    <div className="main-column-selection">
      <button
        ref={buttonRef}
        onClick={handleButtonClick}
        className="selection-button">
        <img src={columnSelection} alt="Column Selection Icon" />
      </button>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="selection-dropdown">
          {/* Container for overlapping sections */}
          <div className="selection-container">
            <div
              className="selection-container-content">
              <label>My Column Settings</label>
            </div>

            {/* Main content of the dropdown */}
            <ul>
              <div class="selection">
                <button
                  onClick={() => handleResetColumns()}
                  class="btn btn-success reset-selection"
                >
                  {t("resetToDefault")}
                </button>
              </div>
              <div className="selection-li-content">
              {columns.map((column, index) => (
                <li
                  key={column}>
                  <label>
                    <img
                      src={checkboxSelection}
                      alt="Checkbox Selection Dropdown Image"
                    />
                    <input
                      type="checkbox"
                      checked={selectedColumns[column]}
                      onChange={() => onToggleColumn(column)}
                    />
                    {columnDisplayNames[column]}
                  </label>
                </li>
              ))}
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
