import React, { useState } from "react";
import styles from "./tooltip.module.css"; // Create a CSS file for Tooltip styling
import copyText from "../../../../../images/copyText.png";

const Tooltip = ({ drivePath }) => {
  const [toastMessage, setToastMessage] = useState(""); // State for toast message

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    setToastMessage("Link Copied Successfully");

    // Hide the toast after 2000ms
    setTimeout(() => {
      setToastMessage("");
    }, 2000);
  };
  const addressLink = `${drivePath}`;

  return (
    <div className={styles.tooltip}>
      <p>Address Link</p>
      <div className={styles.tooltipText}>
      <a href={addressLink} target="_blank" rel="noopener noreferrer">
        {addressLink}
      </a>
      <button onClick={() => handleCopyLink(addressLink)}><img  className={styles.copyIcon} alt="copy text" src={copyText}/>
      </button>
      </div>
        {/* Toast notification */}
        {toastMessage && (
          <div className={styles.toast}>{toastMessage}</div>
        )}
      
    </div>
  );
};

export default Tooltip;
